import React, { Suspense, lazy } from 'react';
import { ImMap } from "react-icons/im";
import globe from "../assets/images/globe_overview.webp";
import AdvManuLogo from "../assets/svgs/pptx_advancedmanu_logo.svg";
import CyberSecurityLogo from "../assets/svgs/pptx_cybersec_logo.svg";
import ArchEngLogo from "../assets/svgs/pptx_archeng_logo.svg"
import DigitalHealthLogo from "../assets/svgs/pptx_digitalhealth_logo.svg";
import FinServLogo from "../assets/svgs/pptx_financeinsur_logo.svg";
import HumanLifeLogo from "../assets/svgs/pptx_lifesci_logo.svg";
import PlantAnimalLogo from "../assets/svgs/pptx_plantanimal_logo.svg";
import TechLogo from "../assets/svgs/pptx_tech_logo.svg";
import MeetingStock from "../assets/images/meeting-stock.webp";
import EnterpriseGlobe from "../assets/svgs/enterprise_globe.svg";
import GlobalSearch from "../assets/svgs/global-search.svg";
import Rocket from "../assets/svgs/rocket.svg";
import Speed from "../assets/svgs/speed.svg";
import Users from "../assets/svgs/users.svg";
import backgroundImage from '../assets/images/landing-page-bg-2.webp';
import mobileBackgroundImage from '../assets/images/landing-page-bg-small.webp';
import { IoPeopleOutline, IoShieldCheckmarkOutline } from 'react-icons/io5';
import { GoLightBulb } from 'react-icons/go';
import ReportPlaceholder from "../assets/images/placeholder_report.webp";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useNavigate } from 'react-router-dom';
import HeroText from '../components/HeroText';
import LandingHeader from '../components/LandingHeader';
import ParallaxHeader from "../components/ParallaxHeader";
import RegularFade from '../components/animations/RegularFade';
import useIsMobile from '../hooks/useIsMobile';

// Lazy load components
const LayeredPanel = lazy(() => import('../components/LayeredPanel'));
const SectionSlant = lazy(() => import('../components/SectionSlant'));
const SignUp = lazy(() => import('../components/SignUp'));
const SimpleCardContainer = lazy(() => import('../components/SimpleCardContainer'));
const FactGrid = lazy(() => import('../components/FactGrid'));

const facts = [
    { fact: "Accelerate the growth of enterprises within the region and work with regional economic development entities to target key enterprises to relocate to the region.", imageSrc: EnterpriseGlobe },
    { fact: "Keep / don't lose the jobs and the talent we have in the region.", imageSrc: Users },
    { fact: "Accelerate innovation and ensure it is commercialized in the region.", imageSrc: Rocket },
    { fact: "Find and support start-ups and gazelles to ensure they remain in the region.", imageSrc: GlobalSearch },
    { fact: "Maximize regional prosperity by accelerating regional enterprise growth, innovation, and high value, high opportunity jobs.", imageSrc: Speed },
];

const cards = [
    {
        image: AdvManuLogo,
        title: "Advanced Manufacturing",
        count: 123
    },
    {
        image: ArchEngLogo,
        title: "Architecture Engineering",
        count: 123
    },
    {
        image: CyberSecurityLogo,
        title: "Cybersecurity",
        count: 123
    },
    {
        image: DigitalHealthLogo,
        title: "Digital Health",
        count: 123
    },
    {
        image: FinServLogo,
        title: "Financial Services Insurance",
        count: 123
    },
    {
        image: HumanLifeLogo,
        title: "Human Life Sciences",
        count: 123
    },
    {
        image: PlantAnimalLogo,
        title: "Plant & Animal Sciences",
        count: 123
    },
    {
        image: TechLogo,
        title: "Technology",
        count: 123
    },
]

const Loading: React.FC = () => <div>Loading...</div>;

const LandingPage: React.FC = () => {
    const navigate = useNavigate();
    const isMobile = useIsMobile();
    const backgroundImageToUse = isMobile ? mobileBackgroundImage : backgroundImage;

    const iconsWithText = [
        { icon: <IoPeopleOutline className='text-4xl' />, text: 'Collaboration' },
        { icon: <GoLightBulb className='text-4xl' />, text: 'Innovation' },
        { icon: <IoShieldCheckmarkOutline className='text-4xl' />, text: 'Trustworthy' }
    ];

    return (
        <div className='bg-custom-gray'>
            <ParallaxHeader
                title="EnterpriseKC"
                description='Shaping the Future of Enterprise Development in Kansas and Western Missouri'
                backgroundImage={backgroundImageToUse}
            />
            <div className='container'>
                <LandingHeader
                    tagline='Regional Growth Through Innovation'
                    title='Actionable Strategies for Accelerated Economic Growth in Kansas and Western Missouri'
                    subtitle="EnterpriseKC is a business-led, systems driven, “think and do” tank. Our purpose is to maximize regional prosperity for all by accelerating enterprise growth, innovation, and high value, high opportunity jobs through business-led, results-oriented strategies and programs."
                    image={MeetingStock}
                />
                <HeroText
                    tagline='Data-Driven, Results-Oriented'
                    title='Connecting Opportunities Across Key Clusters'
                    subtitle="We identify unique assets across the region and then collaborate with industry, academia, and government to leverage those assets through strategies and programs that result in accelerated enterprise growth and economic prosperity throughout Kansas and Western Missouri, cluster by cluster."
                    className='mb-8 flex flex-col items-center justify-center text-center'
                />
            </div>
            <Suspense fallback={<Loading />}>
                <div className='container'>
                    <SimpleCardContainer cards={cards} />
                </div>
                <LayeredPanel
                    title="Asset Map"
                    subtitle="Explore the asset map to discover resources and opportunities available within the region. Educational Institutions, Degree Options, Programs, and more, the asset map is your guide."
                    buttonText="Visit Map"
                    onButtonClick={() => navigate('/asset-map')}
                    buttonIcon={<ImMap className='text-lg fill-ekc-green' />}
                    image={globe}
                    icons={iconsWithText}
                />
                <SectionSlant
                    tagline="Insights, Narratives, Analyses"
                    title="Explore Our Resources"
                    subtitle="Discover EnterpriseKC’s vault of in-depth cluster analyses, project narratives, infographics, Quarterly Cybersecurity Reports, and more. Unlock EKC’s views on economic development in our region and find out about our approach to enterprise growth and prosperity for Kansas and Western Missouri."
                    image={<LazyLoadImage src={ReportPlaceholder} alt="Report Placeholder" threshold={150} className="rounded-lg" />}
                    topColor='from-custom-gray'
                    bottomColor='to-custom-black'
                    buttonText="Unlock Resources"
                />
                <FactGrid
                    title="Mission Imperatives"
                    subtitle='Discover the reasons behind our mission.'
                    facts={facts}
                />
                <SignUp />
            </Suspense>
        </div >
    );
}

export default RegularFade(LandingPage);
