// StatsCard.tsx
import React from 'react';
import CountUp from 'react-countup';
import { Stat } from '../../common/types';
import GrowHover from '../animations/GrowHover';

interface StatsCardProps {
    stats: Stat[];
}

const StatsCard: React.FC<StatsCardProps> = ({ stats }) => {
    return (
        <div className='w-full'>
            <div className="grid grid-cols-[repeat(auto-fill,325px)] gap-8 text-white font-jakarta relative justify-center">
                {/* Stat Card */}
                {stats.map((stat, index) => {
                    if (stat.label === "Total Companies") return null; // Skip rendering "Total Companies" as its own card

                    return (
                        <GrowHover scale={1.1} key={index}>
                            <div className="flex flex-col sm:flex-row mx-auto justify-around items-center sm:items-left border-2 relative rounded-md p-8 gap-4">
                                <div className="w-16 h-16 flex-shrink-0 mb-4">
                                    <stat.image className="w-full h-full" />
                                </div>
                                <div className="flex flex-col">
                                    <div className='flex justify-center sm:justify-start items-center'>
                                        <CountUp end={stat.value} duration={3.75} className="text-4xl font-bold" decimals={stat.label.includes('%') ? 1 : 0} />
                                        {stat.label.includes('%') ? <span className="text-4xl font-bold">%</span> : ' '}
                                    </div>
                                    <span className="text-sm mt-1 text-center sm:text-left sm:whitespace-nowrap">
                                        {stat.label}
                                        {stat.label === 'Verified Companies' && (
                                            <div className="text-xs mt-1 text-center sm:text-left sm:whitespace-nowrap absolute right-2 bottom-2">
                                                <span className=''>*</span>{stats.find(s => s.label === "Total Companies")?.value} Total Companies
                                            </div>
                                        )}
                                    </span>
                                </div>
                            </div>
                        </GrowHover>
                    );
                })}
            </div>
            <div className='mt-8 container text-base'>
                *The EnterpriseKC Datasphere aggregates data from local, national, and global public and private sources. Cluster data on firms in Kansas and Western Missouri is categorized as <abbr title='An Enterprise is designated as a Primary Enterprise if its corporate headquarters is located within the region. A Primary Enterprise has the greatest potential impact on the regional economy because of the effect of the dollars imported back to the region and the employment growth within the region. Examples of Primary Enterprises include Cerner, Hallmark and Garmin. '>Primary Enterprises</abbr>, <abbr title='A Secondary Enterprise is an Enterprise whose corporate headquarters is located outside the region. The potential economic impact of a Secondary Enterprise is less than a Primary Enterprise because the revenue dollars go to another region. However, that does not diminish the importance of a Secondary Enterprise because they can employ hundreds or thousands within the region. Examples of Secondary Enterprises include General Motors, Ford and Turner Construction.'>Secondary Enterprises</abbr>, or <abbr title='One of the two Firm Classifications. A business is a for-profit entity within a given Cluster that generates less than 70 percent of their revenue from outside of the Region such as local bank, local law firm or accounting firms, or a regional service provider. While all businesses are important to the economic ecosystem, businesses contribute less Economic Lift than an Enterprise.'>Businesses</abbr>. The total reflects the current number of verified firms in each designation.
                Verification requires a manual review of the firm’s  information to confirm data integrity. The percentage of revenue and employment attributed to Enterprises highlights their significance to regional prosperity. EKC Data Curators continually augment the data in the Datasphere as new information becomes available.
            </div>
        </div>
    );
};

export default StatsCard;
