import { AnimatePresence } from 'framer-motion';
import { lazy, Suspense, useEffect } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import DashboardLayout from '../../layouts/DashboardLayout';
import MainLayout from '../../layouts/MainLayout';
import ContactPage from '../../pages/ContactPage';
import DataPage from '../../pages/DataPage';
import GrowWithGooglePage from '../../pages/GrowWithGooglePage';
import LandingPage from '../../pages/LandingPage';
import PrivacyPolicyPage from '../../pages/PrivacyPolicyPage';
import ResourcesPage from '../../pages/ResourcesPage';
import AdvancedManufacturing from '../../pages/clusters/AdvancedManufacturingPage';
import ArchitectureEngineering from '../../pages/clusters/ArchitectureEngineeringPage';
import Cybersecurity from '../../pages/clusters/CybersecurityPage';
import DigitalHealthPage from '../../pages/clusters/DigitalHealthPage';
import FinancialServices from '../../pages/clusters/FinancialServicesPage';
import HumanLifeSciencesPage from '../../pages/clusters/HumanLifeSciencesPage';
import PlantAnimalSciencesPage from '../../pages/clusters/PlantAnimalSciencesPage';
import TechnologyPage from '../../pages/clusters/TechnologyPage';
import DashboardPage from '../../pages/dashboard/DashboardPage';
import GlobeDashboardPage from '../../pages/dashboard/GlobeDashboardPage';
import LoginPage from '../../pages/dashboard/LoginPage';
import LogoutPage from '../../pages/dashboard/LogoutPage';
import RegisterPage from '../../pages/dashboard/RegisterPage';
import ResourceDashboardPage from '../../pages/dashboard/ResourceDashboardPage';
import CyberRangePage from './../../pages/projects/CyberRangePage';
import DataSpherePage from './../../pages/projects/DataSpherePage';
import SecureSpaceCampusPage from './../../pages/projects/SecureSpaceCampusPage';
import TalentPlatformPage from './../../pages/projects/TalentPlatformPage';
import LoadingScreen from '../LoadingScreen';
import OurApproachPage from '../../pages/OurApproachPage';
import CyberRangeLandingPage from '../../pages/CyberRangeLandingPage';
import Operation287 from '../../pages/Operation287';

const GlobePage = lazy(() => import('../../pages/GlobePage'));
declare global {
    interface Window {
        gtag: (...args: any[]) => void;
    }
}

function AnimatedRoutes() {
    const location = useLocation();

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        window.gtag("event", "page_view", { page_path: location.pathname + location.search });
    }, [location]);

    return (
        <AnimatePresence mode="wait">
            <Routes location={location} key={location.pathname}>
                {/* Main Layout */}
                {location.pathname.startsWith('/defendtomorrow') ? null : (
                    <Route path='*' element={<Navigate to="/" />} />
                )}
                <Route element={<MainLayout />} >
                    <Route path="/" element={<LandingPage />} />
                    <Route path="/asset-map" element={
                        <Suspense fallback={<LoadingScreen />}>
                            <GlobePage />
                        </Suspense>
                    } />
                    <Route path="/contact-us" element={<ContactPage />} />
                    <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
                    <Route path="/resources" element={<ResourcesPage />} />
                    <Route path="/advanced-manufacturing" element={<AdvancedManufacturing />} />
                    <Route path="/cybersecurity" element={<Cybersecurity />} />
                    <Route path="/architecture-engineering" element={<ArchitectureEngineering />} />
                    <Route path="/financial-services-insurance" element={<FinancialServices />} />
                    <Route path="/human-life-sciences" element={<HumanLifeSciencesPage />} />
                    <Route path="/plant-&-animal-sciences" element={<PlantAnimalSciencesPage />} />
                    <Route path="/technology" element={<TechnologyPage />} />
                    <Route path="/digital-health" element={<DigitalHealthPage />} />
                    <Route path="/datasphere" element={<DataSpherePage />} />
                    <Route path="/heartland-cyber-range" element={<CyberRangePage />} />
                    <Route path="/secure-space-campus" element={<SecureSpaceCampusPage />} />
                    <Route path="/talent-platform" element={<TalentPlatformPage />} />
                    <Route path="/grow-with-google" element={<GrowWithGooglePage />} />
                    <Route path="/our-approach" element={<OurApproachPage />} />
                    <Route path="/defend-tomorrow" element={<CyberRangeLandingPage />} />
                    <Route path="/defendtomorrow" element={<Navigate to="/defend-tomorrow" />} />
                    <Route path="/operation287" element={<Operation287 />} />   
                </Route>

                {/* eImpact Data */}
                <Route path="/explore-data/:state/:industry" element={<DataPage />} />
                <Route path="/explore-data/:state/" element={<DataPage />} />

                {/* Admin Dashboard */}
                <Route path="/admin" element={<Navigate to="/admin/login" />} />
                <Route path="/admin/login" element={<LoginPage />} />
                <Route path="/admin/register" element={<RegisterPage />} />
                <Route path="/admin/logout" element={<LogoutPage />} />

                {/* Dashboard */}
                <Route element={<DashboardLayout />} >
                    <Route path="/dashboard" element={<DashboardPage />} />
                    <Route path="/dashboard/asset-map" element={<GlobeDashboardPage />} />
                    <Route path="/dashboard/resources" element={<ResourceDashboardPage />} />
                </Route>

                    
            </Routes>
        </AnimatePresence >
    );
}

export default AnimatedRoutes;