import { useState } from "react";
import usePreventBodyScroll from '../../hooks/usePreventBodyScroll';
import useTable from '../../hooks/useTable';
import FadeLeft from '../animations/FadeLeft';
import { useAddResource, useUploadFile } from '../../hooks/resourceHooks'; // Adjust as needed for your add resource hook
import { v4 as uuidv4 } from 'uuid';
import getPdfImageUrl from '../../utils/pdfToImage';

const DrawerAddResource = () => {
    const [title, setTitle] = useState('');
    const [subtitle, setSubtitle] = useState('');
    const [tag, setTag] = useState('');
    const [imageLink, setImageLink] = useState('');
    const [pdfLink, setPdfLink] = useState('');
    const addResource = useAddResource();
    const uploadFile = useUploadFile();
    const { handleDrawerAddToggle, openSnackbar } = useTable();
    usePreventBodyScroll();

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!title || !subtitle || !tag || !imageLink || !pdfLink) {
            openSnackbar('Please fill out all required fields');
            return;
        }

        const resourceData = {
            title,
            subtitle,
            tag,
            imageLink,
            pdfLink
        };

        addResource.mutate({
            resourceData: resourceData,
            apiKey: 'ZcgMq-tTnVqXkf97lYSEfdQpxJ_FuMO4QuDUT9vB0Vc'
        }, {
            onSuccess: () => {
                openSnackbar('Resource added successfully');
                handleDrawerAddToggle(null);
            },
            onError: (error: unknown) => {
                openSnackbar(`Error: ${error}`);
            }
        });
    };

    const handlePdfClick = () => {
        if (title === "") {
            openSnackbar('Please fill out the title field first');
        }
    };
    const handleDragOver: React.DragEventHandler<HTMLLabelElement> = (e) => {
        e.preventDefault();
    };

    const handleDrop: React.DragEventHandler<HTMLLabelElement> = (e) => {
        e.preventDefault();
        const files = e.dataTransfer.files;
        if (files && files.length > 0 && title) {
            const file = files[0];
            if (file.type === 'application/pdf') {
                handlePdfUpload({ target: { files } } as unknown as React.ChangeEvent<HTMLInputElement>);
            }
        }
    };

    const handlePdfUpload = async (e: React.ChangeEvent<HTMLInputElement> | { target: { files: File[] } }) => {
        const file = e.target.files?.[0];
        if (file && file.type === 'application/pdf') {
            const uniqueFileName = uuidv4();
            const imageFileName = `${uniqueFileName}.png`;
            const pdfFileName = `${uniqueFileName}.pdf`;

            try {
                openSnackbar('Converting PDF to image, please wait...');
                const pdfImage = await getPdfImageUrl(URL.createObjectURL(file));
                const blob = await fetch(pdfImage).then(r => r.blob());
                const convertedImageFile = new File([blob], imageFileName, { type: 'image/png' });

                // First, upload the converted image
                uploadFile.mutate({
                    fileData: convertedImageFile,
                    resourceData: { title: imageFileName },
                    apiKey: 'ZcgMq-tTnVqXkf97lYSEfdQpxJ_FuMO4QuDUT9vB0Vc'
                }, {
                    onSuccess: (uploadResponse) => {
                        setImageLink(uploadResponse.url);
                        openSnackbar('Image converted and uploaded successfully, please wait for the PDF to upload...');
                        const pdfBlob = new Blob([file], { type: 'application/pdf' });
                        const renamedPdfFile = new File([pdfBlob], pdfFileName, { type: 'application/pdf' });
                        uploadFile.mutate({
                            fileData: renamedPdfFile,
                            resourceData: { title: pdfFileName },
                            apiKey: 'ZcgMq-tTnVqXkf97lYSEfdQpxJ_FuMO4QuDUT9vB0Vc'
                        }, {
                            onSuccess: (pdfUploadResponse) => {
                                setPdfLink(pdfUploadResponse.url);
                                openSnackbar('PDF uploaded successfully');
                            },
                            onError: (error: unknown) => {
                                openSnackbar(`Error uploading PDF: ${error}`);
                            }
                        });
                    },
                    onError: (error: unknown) => {
                        openSnackbar(`Error uploading image: ${error}`);
                    }
                });
            } catch (error) {
                console.error('Error converting PDF to image:', error);
            }
        }
    };

    return (
        <FadeLeft velocity={1000} opacity={1} translateX={"-100%"} className="font-jakarta fixed top-0 left-0 z-40 w-full h-screen max-w-3xl p-4 overflow-y-auto bg-white dark:bg-gray-800">
            <form onSubmit={handleSubmit} className="grid grid-cols-3 gap-4">
                <div className="col-span-3">
                    <h5 id="drawer-label" className="inline-flex items-center mb-6 text-sm font-semibold text-gray-500 uppercase dark:text-gray-400">Add Resource</h5>
                    <button type="button" onClick={() => handleDrawerAddToggle(null)} className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 absolute top-2.5 right-2.5 inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white">
                        Close
                    </button>
                </div>

                {/* Title and Subtitle fields */}
                <div className="space-y-4 col-span-2">
                    <div>
                        <label htmlFor="title" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Title</label>
                        <input onChange={e => setTitle(e.target.value)} value={title} type="text" name="title" id="title" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white" placeholder="Title" required />
                    </div>
                    <div>
                        <label htmlFor="subtitle" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Subtitle</label>
                        <input onChange={e => setSubtitle(e.target.value)} value={subtitle} type="text" name="subtitle" id="subtitle" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white" placeholder="Subtitle" required />
                    </div>
                    <div>
                        <label htmlFor="pdfLink" className={`flex flex-col items-center justify-center w-full h-64 border-2 rounded-lg cursor-pointer ${!title ?
                            'border-gray-500 bg-gray-300 text-gray-400 cursor-not-allowed' :
                            'border-gray-300 bg-gray-50 hover:bg-gray-100 dark:bg-gray-700 dark:border-gray-600 dark:hover:bg-gray-600 dark:hover:border-gray-500'
                            }`}
                            onDrop={handleDrop}
                            onDragOver={handleDragOver}
                            onClick={handlePdfClick}
                        >
                            <div className="flex flex-col items-center justify-center pt-5 pb-6"  >
                                <svg className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2" />
                                </svg>
                                <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span className="font-semibold">Click to upload</span> or drag and drop</p>
                                <p className="text-xs text-gray-500 dark:text-gray-400">PDF</p>
                            </div>
                            <input type="file"
                                id="pdfLink"
                                name="pdfLink"
                                onChange={handlePdfUpload}
                                className={`hidden`}
                                disabled={!title}
                            />
                        </label>
                    </div>
                </div>

                {/* Tag, Image Link, and PDF Link fields */}
                <div className="space-y-4 col-span-1">
                    {/* Tag Field */}
                    <div>
                        <label htmlFor="tag" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Tag</label>
                        <input onChange={e => setTag(e.target.value)} value={tag} type="text" name="tag" id="tag" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white" placeholder="Tag" required />
                    </div>
                    {/* Image Link Field */}
                    <div>
                        <label htmlFor="imageLink" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Image Link</label>
                        <input onChange={e => setImageLink(e.target.value)} value={imageLink} type="text" name="imageLink" id="imageLink" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white" placeholder="Image Link" required />
                    </div>
                    {/* PDF Link Field */}
                    <div>
                        <label htmlFor="pdfLink" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">PDF Link</label>
                        <input onChange={e => setPdfLink(e.target.value)} value={pdfLink} type="text" name="pdfLink" id="pdfLink" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white" placeholder="PDF Link" required />
                    </div>
                    {/* Show uploaded */}
                    <div>
                        <img src={imageLink} alt="Uploaded PDF" className="w-full object-cover" />
                    </div>
                </div>

                {/* Submit and Cancel Buttons */}
                <div className="grid grid-cols-2 gap-4 col-span-3 mt-6">
                    <button type="submit" className="text-white bg-ekc-blue hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-ekc-blue dark:focus:ring-primary-800">Add Resource</button>
                    <button type="button" onClick={() => handleDrawerAddToggle(null)} className="text-red-600 inline-flex justify-center items-center hover:text-white border border-red-600 hover:bg-red-600 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:border-red-500 dark:text-red-500 dark:hover:text-white dark:hover:bg-red-600 dark:focus:ring-red-900">
                        Cancel
                    </button>
                </div>
            </form>
        </FadeLeft>
    );

};

export default DrawerAddResource;
