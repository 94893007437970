import React, { useEffect, useState, startTransition, Suspense } from 'react';
import { getResources } from '../utils/api';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import Background from '../assets/images/resources-bg.webp';
import useResourceAccess from '../hooks/useResourceAccess';
import lineArt from '../assets/images/line-art-modified.png';
import RegularFade from './../components/animations/RegularFade';
import { AnimatePresence } from 'framer-motion';
import ModalBGImage from '../assets/images/ks-blue-landscape.png';
import { useLocation } from 'react-router-dom';
import { setSearchTerm } from '../redux/slices/searchSlice';


const ResourceGrid = React.lazy(() => import('../components/ResourceGrid'));
const SearchBar = React.lazy(() => import('../components/SearchBar'));
const ResourceRows = React.lazy(() => import('../components/ResourceRows'));
const ResourceHeader = React.lazy(() => import('../components/ResourceHeader'));
const LogoLoading = React.lazy(() => import('../components/animations/LogoLoading'));
const ModalForm = React.lazy(() => import('../components/ModalForm'));

const ResourcesPage: React.FC = () => {
    const [resources, setResources] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<Error | null>(null);
    const { showModal, setShowModal, handleResourceClick } = useResourceAccess();
    const searchTerm = useAppSelector((state) => state.search.terms["resourcesSearch"]);
    const dispatch = useAppDispatch();
    const location = useLocation();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getResources();
                setResources(response.data);
                setIsLoading(false);
            } catch (err) {
                setError(err as Error);
                setIsLoading(false);
            }
        };
        fetchData();

        const searchParams = new URLSearchParams(location.search);
        const prefill = searchParams.get('search');
        if (prefill) {
            dispatch(setSearchTerm({ id: 'resourcesSearch', term: prefill }));
        }
        const accessToken = searchParams.get('token');
        if (accessToken === 'e6a4803e-0e4a-4b41-9410-7f0c26e353ce') {
            localStorage.setItem('filledForm', 'true');
        }
    }, [dispatch, location.search]);

    const handleFormSubmit = () => {
        localStorage.setItem('filledForm', 'true');
    };

    return (
        <div className='bg-custom-gray'>
            <Suspense fallback={<div></div>}>
                <ResourceHeader
                    title="Resources"
                    subtitle="Explore the Knowledge Center"
                    imageLink={Background}
                    backgroundHeaderImage={Background}
                    onResourceClick={handleResourceClick}
                />
                <div className='relative'>
                    {/* Left Line Art */}
                    <div className="hidden xl:block absolute top-0 left-0 h-full lg:w-1/2 xl:w-[311px] bg-no-repeat bg-contain opacity-20" style={{ backgroundImage: `url(${lineArt})` }}></div>

                    {/* Right Line Art */}
                    <div className="hidden xl:block absolute top-0 right-0 h-full lg:w-1/2 xl:w-[311px] bg-no-repeat bg-contain transform rotate-180 opacity-20" style={{ backgroundImage: `url(${lineArt})` }}></div>
                    <div className="container mx-auto px-4 flex flex-col font-jakarta py-16 lg:py-24">
                        <div className='text-center mb-16'>
                            <h4 className='ekc-text-gradient font-bold text-lg mb-4'>Resources</h4>
                            <h2 className='text-4xl md:text-6xl font-semibold'>Whitepapers Just For You</h2>
                        </div>
                        <SearchBar
                            label="Search for resources..."
                            id="resourcesSearch"
                            className='py-4 px-8 rounded-full w-full border-2 border-gray-200 font-jakarta bg-gray-100 z-10 text-black'
                            iconColor='gray'
                        />

                        <div className="flex flex-col my-8">
                            {isLoading && <LogoLoading />}
                            {searchTerm ? (
                                <ResourceGrid resources={resources} onResourceClick={handleResourceClick} />
                            ) : (
                                <ResourceRows resources={resources} onResourceClick={handleResourceClick} />
                            )}
                            <AnimatePresence>
                                {showModal && (
                                    <ModalForm
                                        onClose={() => setShowModal(false)}
                                        pageName="Resources"
                                        onSubmissionComplete={handleFormSubmit}
                                        title="Get Access"
                                        subtitle='Unlock comprehensive insights and the latest in industry intelligence. Gain the advantage with our in-depth reports and expert analyses.'
                                        image={ModalBGImage}
                                    />
                                )}
                            </AnimatePresence>
                        </div>
                        {error && (
                            <div className="text-center text-red-500">
                                {error.message}
                            </div>
                        )}
                    </div>
                </div>
            </Suspense>
        </div>
    );
}

export default RegularFade(ResourcesPage);
